import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import theme from '../../theme/index';

// Select takes a theme prop, so styled comp props are not available, that's why I have to style it here
const SelectArea = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: ${p => p.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: flex-start;

    &>*:first-child {
      margin-bottom: 0.6rem;
    }
  }

  @media(max-width: ${p => p.theme.breakpoints.l}) {
  }

  &>*:last-child {
    width: 20rem;

    @media(max-width: ${p => p.theme.breakpoints.xl}) {
      width: 15rem;
    }
    @media(max-width: ${p => p.theme.breakpoints.l}) {
      width: 100%;
    }
  }
`;

const SelectTitle = styled.h2`
  margin-right: 0.6rem;
`;

export default ({
  defaultOrdering, onOrderingChanged, className, style,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          translations {
            ordering
            newestFirst
            oldestFirst
            idAscending
            idDescending
          }
        }
      }
    }
  `);

  const {
    ordering: orderingTitle,
    newestFirst: newestFirstTitle,
    oldestFirst: oldestFirstTitle,
    idAscending: idAscendingTitle,
    idDescending: idDescendingTitle,
  } = data.site.siteMetadata.translations;

  const orderOptions = [
    { value: 'newestFirst', label: newestFirstTitle },
    { value: 'oldestFirst', label: oldestFirstTitle },
    { value: 'idAscending', label: idAscendingTitle },
    { value: 'idDescending', label: idDescendingTitle },
  ];

  return (
    <SelectArea className={className} style={style}>
      <SelectTitle>{`${orderingTitle}:`}</SelectTitle>
      <Select
        onChange={option => onOrderingChanged(option.value)}
        options={orderOptions}
        defaultValue={orderOptions.find(o => o.value === defaultOrdering)}
        theme={(selectTheme) => ({
          ...selectTheme,
          colors: {
            ...selectTheme.colors,
            text: theme.colors.text,
            primary25: theme.colors.zoma25,
            primary: theme.colors.zoma,
          },
        })}
      />
    </SelectArea>
  );
};
