import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import AngleLeftImage from '../../../assets/svg/angle-left.svg';
import AngleRightImage from '../../../assets/svg/angle-right.svg';
import CloseImage from '../../../assets/svg/close.svg';

import AnimatingButton from '../ui/animatingSvgButton';

const Styles = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled(AnimatingButton)`
  width: 2rem;
  margin: 8px;

  svg {
    fill: ${p => p.theme.colors.background};
  }    
`;

const CardArea = styled.div`
  display: ${p => (p.show ? 'block' : 'none')};

  max-width: 80%;
  max-height: 80%;

  &>* {
    margin: 8px;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Index = styled.p`
  color: ${p => p.theme.colors.background};
  font-size: 1.4rem;
`;

const Close = styled(AnimatingButton)`
  svg {
    width: 1.4rem;
    height: 1.4rem;
    fill: ${p => p.theme.colors.background};
    * {
      fill: ${p => p.theme.colors.background};
    }
  }  
`;

const Card = styled.div`
  background-color: ${p => p.theme.colors.background};  

  padding: 32px;
  border-radius: 12px;

  display: flex;
  flex-direction: column;

  img {
    max-height: 70vh;
    align-self: center;
  }

  position: relative;
`;

const DescriptionArea = styled.div`
  padding-top: 16px;

  &>* {
    margin: 4px;
  }
`;

const Title = styled.p`
  font-weight: ${p => p.theme.fonts.weights.bold};
  font-size: 1.8rem;
`;

const Description = styled.p`
  font-size: 1.2rem;
`;

const NavigationArea = styled.button`
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
`;

const DecrementArea = styled(NavigationArea)`
  left: 0;
`;

const IncrementArea = styled(NavigationArea)`
  right: 0;
`;

export default ({ items, startIndex, onClose }) => {
  const [currentIndex, _setCurrentIndex] = useState(startIndex);
  const currentIndexRef = useRef(currentIndex);
  const setCurrentIndex = data => {
    currentIndexRef.current = data;
    _setCurrentIndex(data);
  };

  const increment = () => {
    if (currentIndexRef.current < items.length - 1) {
      setCurrentIndex(currentIndexRef.current + 1);
    }
  };

  const decrement = () => {
    if (currentIndexRef.current > 0) {
      setCurrentIndex(currentIndexRef.current - 1);
    }
  };

  // if start index is only set after photo carousel is rendered already, we need to update it
  useEffect(() => {
    if (startIndex !== currentIndex) {
      setCurrentIndex(startIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startIndex]);
  const keyboardNavigationListener = (event) => {
    if (event.keyCode === 37) {
      decrement();
    } else if (event.keyCode === 39) {
      increment();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keyup', keyboardNavigationListener);
      return () => window.removeEventListener('keyup', keyboardNavigationListener);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styles>
      <Arrow onClick={decrement}>
        <AngleLeftImage />
      </Arrow>
      {items.map((item, index) => (
        <CardArea key={item.name || item.title || item.description} show={index === currentIndex}>
          <CardHeader>
            <Index>
              {`${index + 1}/${items.length}`}
            </Index>
            <Close onClick={onClose}>
              <CloseImage />
            </Close>
          </CardHeader>

          <Card>
            <DecrementArea onClick={decrement} />
            <IncrementArea onClick={increment} />
            <img src={item.image?.url} alt={item.name} />

            <DescriptionArea>
              <Title>
                {item.name}
              </Title>
              {item.description && (
              <Description>
                {item.description}
              </Description>
              )}
            </DescriptionArea>
          </Card>
        </CardArea>
      ))}
      <Arrow onClick={increment}>
        <AngleRightImage />
      </Arrow>
    </Styles>
  );
};
