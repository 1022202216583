import React from 'react';
import styled, { css } from 'styled-components';

const Styles = styled.div`
  position: relative;
  ${p => p.minHeight && css`
    min-height: 200px;
  `}
`;

const ImageStyles = styled.img`  
  ${p => !p.show && css`
    display: none;
  `}
`;

const ImagePlaceHolder = styled.div`
  min-height: 200px;
  background: linear-gradient(-45deg, ${p => p.theme.colors.zoma} 0%, ${p => p.theme.colors.zomaLight} 48%, ${p => p.theme.colors.zomaLight} 52%, ${p => p.theme.colors.zoma} 100%);
  background-size: 180% 180%;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  @keyframes LoadingImageAnimation { 
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 0% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  animation: LoadingImageAnimation 8s ease infinite;
  /* ${p => p.index && css`
    animation-delay: ${p.index * 20}ms;
  `} */

  /* transition: opacity 0.4s ease-out; */

  ${p => !p.show && css`
    /* opacity: 0; */
    display: none;
  `}
`;

export default ({
  index, src, alt, onLoad, showImage,
}) => (
  <Styles minHeight={!showImage}>
    <ImageStyles src={src} alt={alt} onLoad={onLoad} onError={onLoad} show={showImage} />
    <ImagePlaceHolder show={!showImage} index={index} />
  </Styles>
);
