import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const animationDuration = 200;

const Styles = styled.div`
  position: fixed;
  z-index: 3;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: ${p => p.theme.colors.overlay50};

  &>* {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 4;
  }
  
  &.overlay-enter {
    opacity: 0;
  }
  &.overlay-enter-active {
    opacity: 1;
    transition: opacity ${() => animationDuration}ms ease-out;
  }
  &.overlay-exit {
    opacity: 1;
  }
  &.overlay-exit-active {
    opacity: 0;
    transition: opacity ${() => animationDuration}ms ease-out;
  }
`;

export default ({
  show, className, style, children, onClick,
}) => {
  const handleClick = (e) => {
    if (e.target.id === 'overlay') {
      onClick();
    }
  };

  return (
    <CSSTransition
      in={show}
      timeout={animationDuration}
      classNames="overlay"
      mountOnEnter
      unmountOnExit
    >
      <Styles
        id="overlay"
        className={className}
        style={style}
        onClick={(e) => handleClick(e)}
      >
        {children}
      </Styles>
    </CSSTransition>
  );
};
