import React, { useState, useRef } from 'react';

import Grid from '../ui/grid';
import Card from '../ui/card';

export default ({
  items,
  onItemClicked,
}) => {
  const loadedImagesRef = useRef(items.reduce((accum, curr) => ({ ...accum, [`${curr.id} ${curr.name}`]: false }), {}));
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  const onImageLoaded = (key) => {
    const newObj = { ...loadedImagesRef.current, [key]: true };
    loadedImagesRef.current = newObj;

    let allLoaded = true;
    Object.values(newObj).forEach(hasLoaded => {
      if (!hasLoaded) {
        allLoaded = false;
      }
    });
    if (allLoaded) {
      setAllImagesLoaded(true);
    }
  };

  return (
    <Grid>
      {items
        .map((item, index) => (
          <Card
            index={index}
            id={`${item.id} ${item.name}`}
            key={`${item.id} ${item.name}`}
            title={`${item.id || ''} ${item.name}`}
            description={item.description}
            imageUrl={
              item.image?.formats?.small?.url
              || item.image?.formats?.medium?.url
              || item.image?.formats?.large?.url
              || item.image?.url
            }
            onImageLoaded={onImageLoaded}
            showInfo={allImagesLoaded}
            link={item.link}
            onClick={() => onItemClicked && onItemClicked(index, item, items)}
          />
        ))}
    </Grid>
  );
};
