import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  margin: 1rem 0;

  &>*:last-child {
    margin-right: 0;
  }

  &>* {
    width: 92%;
    margin: 4%;
  }

  @media(min-width: ${p => p.theme.breakpoints.s}) {
    &>* {
      width: 42%;
      margin: 4%;
    }
  }

  @media(min-width: ${p => p.theme.breakpoints.xl}) {
    &>* {
      width: 27%;
      margin: 3%;
    }
  }

  @media(min-width: ${p => p.theme.breakpoints.xxl}) {
    &>* {
      width: 16%;
      margin: 2%;
    }
  }
`;

export default ({ children }) => (
  <Styles>
    {children}
  </Styles>
);
