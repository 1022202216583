import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import useShouldAnimateOnHover from '../../hooks/useShouldAnimateOnHover';

import PageTransitionLink from './pageTransitionLink';
import Gift from '../images/gift';
import Image from './image';

const Styles = styled.div`
  cursor: pointer;  
`;

const ImageArea = styled.div`
  width: 100%;
  img {
    width: 100%;
    display: block;
  }

  border-radius: 18px;
  overflow: hidden;
  
  background-color: ${p => p.theme.colors.background};
  box-shadow: ${p => p.theme.shadow.base};

  ${p => p.shouldAnimate && css`
    transition: all 0.3s ease-out;
    &:hover {
      transform: scale(1.1);
      box-shadow: ${() => p.theme.shadow.strong};
    }
  `}
`;

const TextArea = styled.div`
  width: 100%;
  padding: 1.6rem 1rem;
  ${p => p.mobile && css`
    padding: 0.8rem 0.8rem;
  `}
`;

const Title = styled.h2`
  font-weight: ${p => p.theme.fonts.weights.bold};
  font-size: 1.2rem;
`;

const Description = styled.p`
  font-weight: ${p => p.theme.fonts.weights.regular};
  font-size: 0.9rem;
  margin-top: 0.3rem;
`;

export default ({
  index,
  id,
  title,
  description,
  imageUrl,
  onImageLoaded,
  showInfo,
  link,
  onClick,
}) => {
  const shouldAnimate = useShouldAnimateOnHover();

  useEffect(() => {
    if (!imageUrl && onImageLoaded) {
      onImageLoaded(id);
    }
  }, [id, imageUrl, onImageLoaded]);

  const Content = ({ shouldRespondToClicks }) => (
    <Styles onClick={shouldRespondToClicks ? onClick : undefined}>
      <ImageArea shouldAnimate={shouldAnimate && showInfo}>
        {
          imageUrl
            ? (
              <Image
                index={index}
                key={title}
                src={imageUrl}
                alt={title}
                onLoad={() => onImageLoaded && onImageLoaded(id)}
                showImage={showInfo}
              />
            )
            : <Gift />
        }
      </ImageArea>
      <TextArea mobile={!shouldAnimate}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextArea>
    </Styles>
  );
  return link ? <PageTransitionLink to={link}><Content /></PageTransitionLink> : <Content shouldRespondToClicks />;
};
