import React, { useState } from 'react';
import styled from 'styled-components';
import AsyncGrid from '../components/composite/asyncGrid';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PageHeader from '../components/ui/pageHeader';
import Overlay from '../components/composite/overlay';
import PhotoCarousel from '../components/composite/photoCarousel';
import Ordering from '../components/ui/ordering';

import useShouldAnimateOnHover from '../hooks/useShouldAnimateOnHover';

const ProductOrdering = styled(Ordering)`
  margin: 2rem;
`;

const ProductsPage = ({ pageContext }) => {
  const { subCategoryName, products } = pageContext;

  const defaultOrdering = 'newestFirst';
  const [ordering, setOrdering] = useState(defaultOrdering);

  const [photoCarouselIndex, setPhotoCarouselIndex] = useState(null);

  const shouldAnimate = useShouldAnimateOnHover();

  const onProductClicked = (index) => {
    setPhotoCarouselIndex(index);
  };

  const orderProducts = (prods, order) => {
    switch (order) {
      case 'newestFirst':
        return [...prods.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))];
      case 'oldestFirst':
        return [...prods.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))];
      case 'idAscending':
        return [...prods.sort((a, b) => (a.id < b.id ? -1 : 1))];
      case 'idDescending':
        return [...prods.sort((a, b) => (a.id > b.id ? -1 : 1))];
      default:
        return [...prods];
    }
  };

  return (
    <Layout noMainMarginsMobile>
      <SEO title={subCategoryName} />

      <PageHeader>
        {subCategoryName}
      </PageHeader>

      <ProductOrdering
        defaultOrdering={defaultOrdering}
        onOrderingChanged={newOrdering => setOrdering(newOrdering)}
      />
      <AsyncGrid
        items={orderProducts(products, ordering)}
        onItemClicked={onProductClicked}
      />

      <Overlay
        show={photoCarouselIndex !== null && shouldAnimate}
        onClick={() => setPhotoCarouselIndex(null)}
      >
        <PhotoCarousel
          items={orderProducts(products, ordering)}
          startIndex={photoCarouselIndex}
          onClose={() => setPhotoCarouselIndex(null)}
        />
      </Overlay>
    </Layout>
  );
};

export default ProductsPage;
